import { RepositorioCatalogo } from './repositorio-catalogo';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TipoEstatusCliente } from '../entidades/tipo-estatus-cliente';
import { EstatusParaCV } from '../entidades/estatusParaCV';

export class RepositorioTipoEstatusCliente extends RepositorioCatalogo<TipoEstatusCliente> {
  constructor(http: HttpClient) {
    super(http, 'TiposEstatusClientes');
  }

  obtenerTipoEstatusCliente(idEstatus: number, fecha: any): Observable<TipoEstatusCliente> {
    return this.clienteHttp.get(`${this.ruta}/ObtenerTipoEstatusCliente/${idEstatus}`).pipe(
      map((data: TipoEstatusCliente) => {
        return data;
      })
    );
  }

  tipoEstatusTodos(): Observable<TipoEstatusCliente[]> {
    let ruta = `${this.ruta}/TipoEstatusTodos`;
    return this.clienteHttp.get(ruta)
      .pipe(map((data: TipoEstatusCliente[]) => {
        return data;
      }));
  }


  guardoEstatusCV(documento: EstatusParaCV): Observable<EstatusParaCV> {
    let ruta = `${this.ruta}/GuardoEstatusCV`;
    return this.clienteHttp.post<EstatusParaCV>(ruta, documento);
  }

  AsignoEstatusEntregaTotal(idVenta: number, idEstatus: number): Observable<void> {
    const ruta = `${this.ruta}/AsignoEstatusEntregaTotal/${idVenta}/${idEstatus}`;
    return this.clienteHttp.get<void>(ruta);
  }


}
