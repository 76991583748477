import { RepositorioCatalogo } from './repositorio-catalogo';
import { HttpClient } from '@angular/common/http';
import { CatalogosCotizacion } from '../entidades/catalgos-cotizacion';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class RepositorioPrioridadCotizacion extends RepositorioCatalogo<CatalogosCotizacion> {
  constructor(http: HttpClient) {
    super(http, 'PrioridadesCotizacion');
  }

  activar(id: number): Observable<void> {
    let ruta = `${this.ruta}/Activar/${id}`;
    return this.clienteHttp.put(ruta, null)
      .pipe(map(() => null));
  }

  desactivar(id: number): Observable<void> {
    let ruta = `${this.ruta}/Desactivar/${id}`;
    return this.clienteHttp.put(ruta, null)
      .pipe(map(() => null));
  }

}
