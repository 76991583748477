import { HttpClient } from '@angular/common/http';
import { Texto } from '../entidades/texto';
import { RepositorioCatalogo } from './repositorio-catalogo';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { TextoEmpleados } from '../entidades/texto-empleados';

export class RepositorioTextos extends RepositorioCatalogo<Texto> {
  constructor(http: HttpClient) {
    super(http, 'TextosPredeterminados');
  }

  activar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Activar/${id}`;
    return this.clienteHttp.put(ruta, null).pipe(map(() => null));
  }

  desactivar(id: number): Observable<void> {
    const ruta = `${this.ruta}/Desactivar/${id}`;
    return this.clienteHttp.put(ruta, null).pipe(map(() => null));
  }

  obtenerTextosPredeterminados(): Observable<Texto[]> {
    const ruta = `${this.ruta}/ObtenerTextosPredeterminados`;
    return this.clienteHttp.get(ruta).pipe(
      map((data: Texto[]) => {
        return data;
      })
    );
  }
  CambioPermisos(permisos: TextoEmpleados[]): Observable<TextoEmpleados[]> {
    const ruta = `${this.ruta}/CambioPermisos`;
    return this.clienteHttp.post(ruta, permisos).pipe(
      map((data: TextoEmpleados[]) => {
        return data;
      })
    )
  }
  obtenerEmpleadosPorId(textoId: number): Observable<any[]> {
    const ruta = `${this.ruta}/ObtenerEmpleadosPorId/${textoId}`
    return this.clienteHttp.get(ruta).pipe(
      map((data: any[]) => {
        return data;
      })
    );
  }
}
