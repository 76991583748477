import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  EventEmitter
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/api/contexto.service';
import { Moneda } from 'src/app/entidades/moneda';
import { TipoEstatusCliente } from 'src/app/entidades/tipo-estatus-cliente';
import { map } from 'rxjs/operators';
import { CatalogosCotizacion } from 'src/app/entidades/catalgos-cotizacion';

@Component({
  templateUrl: './forma.component.html',
  styleUrls: ['./forma.component.scss']
})
export class FormaModoCotizacionesComponent implements OnInit, OnDestroy {
  forma: FormGroup;
  estatus: CatalogosCotizacion;
  monedas: Observable<Moneda[]>;
  idSeleccionado = 0;
  cargando = false;
  subsGuardar: Subscription;
  guardado: EventEmitter<void> = new EventEmitter<void>();
  habilitar = false;
  get f() {
    return this.forma.controls;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public id: number,
    private ventana: MatDialogRef<FormaModoCotizacionesComponent>,
    private formBuilder: FormBuilder,
    private ctx: Contexto,
    private alerta: ServicioAlerta
  ) {
    if (id > 0) {
      this.idSeleccionado = id;

      this.ctx.modosCotizacion.obtener(id).subscribe(a => {
        this.estatus = a;
        Object.assign(this.forma.value, this.estatus);
        this.forma.reset(this.forma.value);
        this.habilitar = true;
      });
    } else {
      this.idSeleccionado = 0;
      this.estatus = new CatalogosCotizacion();
    }
  }

  ngOnInit() {
    this.forma = this.formBuilder.group({
      id: [0, Validators.required],
      descripcion: [{ value: '', disabled: true }],
      color: ['', Validators.required],
    });
  }

  limpiar(): void {
    this.forma.reset({ id: 0 });
  }

  async guardar(): Promise<void> {

    let codigoColores = await this.ctx.modosCotizacion
    .obtenerTodos()
    .pipe(map(tiposEstatusClientes => tiposEstatusClientes.map(objeto => objeto.color)))
    .toPromise();

    let colorSelectedForm = this.forma.get('color').value;

    let save = codigoColores.includes(colorSelectedForm);

    
    if (this.forma.valid) {
      this.cargando = true;
      Object.assign(this.estatus, this.forma.value);

      let observable: Observable<TipoEstatusCliente>;

      let mensaje = 'Modo de cotizacion guardado.';
      let guardar = true;
      if (this.idSeleccionado === 0) {
        observable = this.ctx.modosCotizacion.guardar(this.estatus);
      } else {
        guardar = true;
        mensaje = 'Modo de cotizacion actualizado';
        observable = this.ctx.modosCotizacion.actualizar(
          this.idSeleccionado.toString(),
          this.estatus
        );
      }

      this.subsGuardar = observable.subscribe(
        () => {
          this.guardado.emit();
          this.alerta.mostrarExito(mensaje);
          this.cargando = false;
          this.limpiar();
          if (guardar) {
            this.ventana.close();
          }
        },
        () => {
          this.alerta.mostrarError('¡Error al guardar!');
          this.cargando = false;
        }
      );
    // }else if(save){
    //   this.alerta.mostrarAdvertencia('El color ya ha sido seleccionado.');
    } else {
      this.alerta.mostrarAdvertencia('Los datos son inválidos.');
    }
  }

  cerrar(): void {
    this.ventana.close();
  }

  ngOnDestroy(): void {
    if (this.subsGuardar) {
      this.subsGuardar.unsubscribe();
    }
  }
}
