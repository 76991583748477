import { RepositorioServicioTransporteCliente } from './repositorio-servicio-transporte-cliente';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RepositorioArticulo } from './repositorio-articulo';
import { RepositorioUsuario } from './repositorio-usuario';
import { RepositorioCamion } from './repositorio-camion';
import { RepositorioSocio } from './repositorio-socio';
import { RepositorioOperador } from './repositorio-operador';

import { RepositorioPermiso } from './repositorio-permiso';
import { RepositorioDetallePermiso } from './repositorio-detallepermiso';
import { RepositorioRol } from './repositorio-rol';
import { RepositorioTipoDocumento } from './repositorio-tipo-documento';
import { RepositorioTipoUsuario } from './repositorio-tipousuario';

import { RepositorioMoneda } from './repositorio-moneda';
import { RepositorioTextos } from './repositorio-texto';
import { RepositorioUnidadesLongitud } from './repositorio-unidades-longitud';
import { RepositorioUnidadesPeso } from './repositorio-unidades-peso';

import { RepositorioDireccionEntrega } from './repositorio-direcciones-entrega';
import { RepositorioTipoCarga } from './repositorio-tipo-carga';
import { RepositorioTrailer } from './repositorio-trailer';

import { RepositorioEstadosProyectos } from './repositorio-estado-proyecto';
import { RepositorioProyectos } from './repositorio-proyecto';
import { RepositorioContactoSocio } from './repositorio-direcciones-socio';
import { RepositorioDireccionSocio } from './repositorio-contacto-socio';
import { RepositorioCorreo } from './repositorio-correo';
import { RepositorioImpuesto } from './repositorio-impuestos';

import { RepositorioAlerta } from './repositorio-alerta';
import { RepositorioDetalleAlerta } from './repositorio-detalles-alerta';
import { RepositorioMedidaLegal } from './repositorio-medida-legal';
import { RepositorioCompra } from './repositorio-compra';
import { RepositorioAduana } from './repositorio-aduana';
import { RepositorioFrontera } from './repositorio-frontera';
import { RepositorioTipoProyecto } from './repositorio-tipo-proyecto';
import { RepositorioTipoUnidad } from './repositorio-tipo-unidad';
import { RepositorioUnidad } from './repositorio-unidad';
import { RepositorioTipoCambio } from './repositorio-tipo-cambio';
import { RepositorioReportes } from './repositorio-reportes';
import { RepositorioVenta } from './repositorio-venta';
import { RepositorioTipoTextos } from './repositorio-tipo-texto';
import { RepositorioPais } from './repositorio-pais';
import { RepositorioEstado } from './repositorio-estado';
import { RepositorioCiudad } from './repositorio-ciudad';
import { RepositorioTablas } from './repositorio-tablas';
import { RepositorioRastreo } from './repositorio-rastreo';
import { RepositorioFacturaCompra } from './repositorio-factura-compra';
import { RepositorioFacturaVenta } from './repositorio-factura-venta';
import { RepositorioProyectoExternos } from './repositorio-proyecto-externo';
import { RepositorioDocumentoFactura } from './repositorio-documento-factura';
import { RepositorioEmpleado } from './repositorio-empleado';
import { RepositorioMotivoCancelacion } from './repositorio-motivo-cancelacion';
import { RepositorioMetaEmpleado } from './repositorio-meta-empleado';
import { RepositorioDocumentoCompra } from './repositorio-documento-compra';
import { RepositorioDocumentoVenta } from './repositorio-documento-venta';
import { RepositorioEstadoRastreo } from './repositorio-estado-rastreo';
import { RepositorioModoTransporte } from './repositorio-modo-transporte';
import { RepositorioTipoProveedor } from './repositorio-tipo-proveedor';
import { RepositorioTipoServicio } from './repositorio-tipo-servicio';
import { RepositorioTipoCamion } from './repositorio-tipo-camion';
import { RepositorioEspecificacionCarga } from './repositorio-especificacion-carga';
import { RepositorioAplicaProveedorMina } from './repositorio-aplica-proveedor-mina';
import { RepositorioLocalidadesSocio } from './repositorio-localidades-socio';
import { RepositorioTipoReporte } from './repositorio-tipo-reporte';
import { RepositorioFiltroReporte } from './repositorio-filtro-reporte';
import { RepositorioGeneradorReportes } from './repositorio-generador-reportes';
import { RepositorioServicios } from './repositorio-servicios';
import { RepositorioTiposServicios } from './repositorio-tipos-servicios';
import { CalculoApi } from './calculo-importes';
import { RepositorioEvaluaciones } from './repositorio-evaluaciones';
import { RepositorioEvaluacionesPE } from './repositorio-evaluaciones-pe';
import { RepositorioExportacionInfo } from './repositorio-exportacion-info';
import { RepositorioReporteComisiones } from './repositorio-reporte.comisiones';
import { RepositorioEnvioPagoFactura } from './repositorio-envio-pago-factura';
import { RepositorioGastoOperacion } from './repositorio-costo';
import { RepositorioConceptoGasto } from './repositorio-tipo-costo';
import { RepositorioComprobante } from './traslado/repositorio-comprobante';
import { RepositorioCfdiEmisor } from './repositorio-cfdi-emisor';
import { RepositorioTiposMotivos } from './repositorio-tipos-motivos';
import { RepositorioTiposDocumentos } from './repositorio-tipos-documentos';
import { RepositorioBitacoraCT } from './repositorio-bitacora-compraCT';
import { RepositorioSolicitudesEmbarques } from './portal-EMonitor/repositorio-solicitudes-embarque';
import { RepositorioOrdenCompraSAP } from './repositorio-orden-compra-sap';
import { RepositorioBitacoraEnvioOCReferi } from './repositorio-bitacoraEnvioOC-referi';
import { RepositorioTiposMotivosDocumentoVenta } from './repositorio-tipos-motivos-doc-venta';
import { RepositorioEnvioOnMonitor } from './repositorio-envio-onmonitor';
import { RepositorioMotivoCierre } from './repositorio-motivo-cierre';
import { RepositorioObjetivos } from './repositorio-objetivos';
import { RepositorioTipoEstatusCliente } from './repositorio-tipo-estatus-cliente';
import { RepositorioTipoOpcion } from './reporitorio-tipoOpcion';
import { RepositorioProveeduria } from './repositorio-proveeduria'
import { RepositorioEstadoFacturacion } from './repositorio-estado-facturacion';
import { RepositorioAutorizaciones } from './repositorio-autorizaciones';
import { RepositorioAntiguedadSaldos } from './repositorio-antiguedad-saldos';
import { RepositorioMotivoDesfase } from './repositorio-motivo-desfase';
import { RepositorioEstadoCotizacion } from './repositorio-estatus-cotizacion';
import { RepositorioModoCotizacion } from './repositorio-modo-cotizacion';
import { RepositorioPrioridadCotizacion } from './repositorio-prioridad-cotizacion';


@Injectable({
    providedIn: 'root',
})
export class Contexto {
    private _articulos: RepositorioArticulo;
    public get articulos(): RepositorioArticulo {
        return this._articulos;
    }

    private _antiguedadSaldos: RepositorioAntiguedadSaldos;
    public get antiguedadSaldos(): RepositorioAntiguedadSaldos {
        return this._antiguedadSaldos;
    }

    private _usuarios: RepositorioUsuario;
    public get usuarios(): RepositorioUsuario {
        return this._usuarios;
    }

    private _camiones: RepositorioCamion;
    public get camiones(): RepositorioCamion {
        return this._camiones;
    }

    private _cfdiEmisor: RepositorioCfdiEmisor;
    public get cfdiEmisor(): RepositorioCfdiEmisor {
        return this._cfdiEmisor;
    }

    private _objetivos: RepositorioObjetivos;
    public get objetivos(): RepositorioObjetivos {
        return this._objetivos;
    }

    private _permisos: RepositorioPermiso;
    public get permisos(): RepositorioPermiso {
        return this._permisos;
    }

    private _detallePermiso: RepositorioDetallePermiso;
    public get detallePermiso(): RepositorioDetallePermiso {
        return this._detallePermiso;
    }

    private _roles: RepositorioRol;
    public get roles(): RepositorioRol {
        return this._roles;
    }

    private _socios: RepositorioSocio;
    public get socios(): RepositorioSocio {
        return this._socios;
    }

    private _operadores: RepositorioOperador;
    public get operadores(): RepositorioOperador {
        return this._operadores;
    }

    private _tipoUsuario: RepositorioTipoUsuario;
    public get tipoUsuario(): RepositorioTipoUsuario {
        return this._tipoUsuario;
    }

    private _tiposDocumento: RepositorioTipoDocumento;
    public get tiposDocumento(): RepositorioTipoDocumento {
        return this._tiposDocumento;
    }

    private _monedas: RepositorioMoneda;
    public get monedas(): RepositorioMoneda {
        return this._monedas;
    }

    private _textos: RepositorioTextos;
    public get textos(): RepositorioTextos {
        return this._textos;
    }

    private _unidadLongitud: RepositorioUnidadesLongitud;
    public get unidadesLongitud(): RepositorioUnidadesLongitud {
        return this._unidadLongitud;
    }

    private _unidadPeso: RepositorioUnidadesPeso;
    public get unidadesPeso(): RepositorioUnidadesPeso {
        return this._unidadPeso;
    }

    private _direccionesServicio: RepositorioDireccionEntrega;
    public get direccionesServicio(): RepositorioDireccionEntrega {
        return this._direccionesServicio;
    }

    private _tiposCarga: RepositorioTipoCarga;
    public get tiposCarga(): RepositorioTipoCarga {
        return this._tiposCarga;
    }

    private _trailer: RepositorioTrailer;
    public get trailers(): RepositorioTrailer {
        return this._trailer;
    }

    private _estadoProyecto: RepositorioEstadosProyectos;
    public get estadosProyecto(): RepositorioEstadosProyectos {
        return this._estadoProyecto;
    }

    private _proyectos: RepositorioProyectos;
    public get proyectos(): RepositorioProyectos {
        return this._proyectos;
    }

    private _contactosSocio: RepositorioContactoSocio;
    public get contactosSocio(): RepositorioContactoSocio {
        return this._contactosSocio;
    }

    private _direccionesSocio: RepositorioDireccionSocio;
    public get direccionesSocio(): RepositorioDireccionSocio {
        return this._direccionesSocio;
    }

    private _correos: RepositorioCorreo;
    public get correos(): RepositorioCorreo {
        return this._correos;
    }

    private _impuestos: RepositorioImpuesto;
    public get impuestos(): RepositorioImpuesto {
        return this._impuestos;
    }

    private _alertas: RepositorioAlerta;
    public get alertas(): RepositorioAlerta {
        return this._alertas;
    }

    private _detalleAlerta: RepositorioDetalleAlerta;
    public get detalleAlerta(): RepositorioDetalleAlerta {
        return this._detalleAlerta;
    }

    private _medidasLegales: RepositorioMedidaLegal;
    public get medidasLegales(): RepositorioMedidaLegal {
        return this._medidasLegales;
    }

    private _compras: RepositorioCompra;
    public get compras(): RepositorioCompra {
        return this._compras;
    }

    private _aduanas: RepositorioAduana;
    public get aduanas(): RepositorioAduana {
        return this._aduanas;
    }

    private _fronteras: RepositorioFrontera;
    public get fronteras(): RepositorioFrontera {
        return this._fronteras;
    }

    private _tiposProyecto: RepositorioTipoProyecto;
    public get tiposProyecto(): RepositorioTipoProyecto {
        return this._tiposProyecto;
    }

    private _tiposUnidad: RepositorioTipoUnidad;
    public get tiposUnidad(): RepositorioTipoUnidad {
        return this._tiposUnidad;
    }

    private _unidades: RepositorioUnidad;
    public get unidades(): RepositorioUnidad {
        return this._unidades;
    }

    private _tiposCambio: RepositorioTipoCambio;
    public get tiposCambio(): RepositorioTipoCambio {
        return this._tiposCambio;
    }

    private _tiposEstatusClientes: RepositorioTipoEstatusCliente;
    public get tiposEstatusClientes(): RepositorioTipoEstatusCliente {
        return this._tiposEstatusClientes;
    }

    private _estadosCotizacion: RepositorioEstadoCotizacion;
    public get estadosCotizacion(): RepositorioEstadoCotizacion {
        return this._estadosCotizacion;
    }

    private _modosCotizacion: RepositorioModoCotizacion;
    public get modosCotizacion(): RepositorioModoCotizacion {
        return this._modosCotizacion;
    }

    private _prioridadesCotizacion: RepositorioPrioridadCotizacion;
    public get prioridadesCotizacion(): RepositorioPrioridadCotizacion {
        return this._prioridadesCotizacion;
    }

    private _tipoOpcion: RepositorioTipoOpcion;
    public get tipoOpcion(): RepositorioTipoOpcion{
        return this._tipoOpcion;
    }

    private _reportes: RepositorioReportes;
    public get reportes(): RepositorioReportes {
        return this._reportes;
    }

    private _ventas: RepositorioVenta;
    public get ventas(): RepositorioVenta {
        return this._ventas;
    }

    private _tipoTextos: RepositorioTipoTextos;
    public get tipoTextos(): RepositorioTipoTextos {
        return this._tipoTextos;
    }

    private _pais: RepositorioPais;
    public get pais(): RepositorioPais {
        return this._pais;
    }

    private _estado: RepositorioEstado;
    public get estado(): RepositorioEstado {
        return this._estado;
    }

    private _ciudad: RepositorioCiudad;
    public get ciudad(): RepositorioCiudad {
        return this._ciudad;
    }

    private _tablas: RepositorioTablas;
    public get tablas(): RepositorioTablas {
        return this._tablas;
    }

    private _facturasCompra: RepositorioFacturaCompra;
    public get facturasCompra(): RepositorioFacturaCompra {
        return this._facturasCompra;
    }

    private _facturasVenta: RepositorioFacturaVenta;
    public get facturasVenta(): RepositorioFacturaVenta {
        return this._facturasVenta;
    }

    private _proyectosExternos: RepositorioProyectoExternos;
    public get proyectosExternos(): RepositorioProyectoExternos {
        return this._proyectosExternos;
    }

    private _documentosFactura: RepositorioDocumentoFactura;
    public get documentosFactura(): RepositorioDocumentoFactura {
        return this._documentosFactura;
    }

    private _rastreo: RepositorioRastreo;
    public get rastreo(): RepositorioRastreo {
        return this._rastreo;
    }

    private _empleados: RepositorioEmpleado;
    public get empleados(): RepositorioEmpleado {
        return this._empleados;
    }

    private _autorizaciones: RepositorioAutorizaciones;
    public get autorizaciones(): RepositorioAutorizaciones {
        return this._autorizaciones;
    }

    private _motivosCancelacion: RepositorioMotivoCancelacion;
    public get motivosCancelacion(): RepositorioMotivoCancelacion {
        return this._motivosCancelacion;
    }

    private _estadosFacturacion: RepositorioEstadoFacturacion;
    public get estadosFacturacion(): RepositorioEstadoFacturacion {
        return this._estadosFacturacion;
    }

    private _motivosCierre: RepositorioMotivoCierre;
    public get motivosCierre(): RepositorioMotivoCierre {
        return this._motivosCierre;
    }

    private _motivosDesfase: RepositorioMotivoDesfase;
    public get motivosDesfase(): RepositorioMotivoDesfase {
        return this._motivosDesfase;
    }

    private _metasEmpleado: RepositorioMetaEmpleado;
    public get metasEmpleado(): RepositorioMetaEmpleado {
        return this._metasEmpleado;
    }

    private _documentosCompra: RepositorioDocumentoCompra;
    public get documentosCompra(): RepositorioDocumentoCompra {
        return this._documentosCompra;
    }

    private _documentosVenta: RepositorioDocumentoVenta;
    public get documentosVenta(): RepositorioDocumentoVenta {
        return this._documentosVenta;
    }

    private _solicitudesEmbarque: RepositorioSolicitudesEmbarques;
    public get solicitudesEmbarque(): RepositorioSolicitudesEmbarques {
        return this._solicitudesEmbarque;
    }

    private _estadoRastreo: RepositorioEstadoRastreo;
    public get estadoRastreo(): RepositorioEstadoRastreo {
        return this._estadoRastreo;
    }

    private _modoTransporte: RepositorioModoTransporte;
    public get modoTransporte(): RepositorioModoTransporte {
        return this._modoTransporte;
    }

    private _tipoProveedor: RepositorioTipoProveedor;
    public get tipoProveedor(): RepositorioTipoProveedor {
        return this._tipoProveedor;
    }

    private _tipoServicio: RepositorioTipoServicio;
    public get tipoServicio(): RepositorioTipoServicio {
        return this._tipoServicio;
    }

    private _tipoCamion: RepositorioTipoCamion;
    public get tipoCamion(): RepositorioTipoCamion {
        return this._tipoCamion;
    }

    private _especificacionCarga: RepositorioEspecificacionCarga;
    public get especificacionCarga(): RepositorioEspecificacionCarga {
        return this._especificacionCarga;
    }

    private _aplicaProveedorMina: RepositorioAplicaProveedorMina;
    public get aplicaProveedorMina(): RepositorioAplicaProveedorMina {
        return this._aplicaProveedorMina;
    }

    private _localidadesSocio: RepositorioLocalidadesSocio;
    public get localidadesSocio(): RepositorioLocalidadesSocio {
        return this._localidadesSocio;
    }

    private _tipoReporte: RepositorioTipoReporte;
    public get tipoReporte(): RepositorioTipoReporte {
        return this._tipoReporte;
    }

    private _filtroReporte: RepositorioFiltroReporte;
    public get filtroReporte(): RepositorioFiltroReporte {
        return this._filtroReporte;
    }

    private _reporteador: RepositorioGeneradorReportes;
    public get reporteador(): RepositorioGeneradorReportes {
        return this._reporteador;
    }

    private _servicios: RepositorioServicios;
    public get servicios(): RepositorioServicios {
        return this._servicios;
    }

    private _tiposServicios: RepositorioTiposServicios;
    public get tiposServicios(): RepositorioTiposServicios {
        return this._tiposServicios;
    }

    private _calculo: CalculoApi;
    public get calculo(): CalculoApi {
        return this._calculo;
    }

    private _evaluaciones: RepositorioEvaluaciones;
    public get evaluaciones(): RepositorioEvaluaciones {
        return this._evaluaciones;
    }

    private _evaluacionesPE: RepositorioEvaluacionesPE;
    public get evaluacionesPE(): RepositorioEvaluacionesPE {
        return this._evaluacionesPE;
    }

    private _exportacion: RepositorioExportacionInfo;
    public get exportacion(): RepositorioExportacionInfo {
        return this._exportacion;
    }
    private _ServicioTransporteCliente: RepositorioServicioTransporteCliente;
    public get ServicioTransporteCliente(): RepositorioServicioTransporteCliente {
        return this._ServicioTransporteCliente;
    }

    private _reporteComisiones: RepositorioReporteComisiones;
    public get reporteComisiones(): RepositorioReporteComisiones {
        return this._reporteComisiones;
    }

    private _envioPagoFactura: RepositorioEnvioPagoFactura;
    public get envioPagoFactura(): RepositorioEnvioPagoFactura {
        return this._envioPagoFactura;
    }


    private _gostoOperacion : RepositorioGastoOperacion;
    public get gastoOperacion() : RepositorioGastoOperacion {
      return this._gostoOperacion;
    }

    private _ConceptoGasto : RepositorioConceptoGasto;
    public get conceptoGasto() : RepositorioConceptoGasto {
      return this._ConceptoGasto;
    }


    private _comprobanteCfdi : RepositorioComprobante;
    public get comprobanteCfdi() : RepositorioComprobante {
      return this._comprobanteCfdi;
    }


    private _tiposMotivos: RepositorioTiposMotivos;
    public get tiposMotivos(): RepositorioTiposMotivos {
        return this._tiposMotivos;
    }

    private _tiposDocumentos: RepositorioTiposDocumentos;
    public get tiposDocumentos(): RepositorioTiposDocumentos {
        return this._tiposDocumentos;
    }

    private _bitacoraCT: RepositorioBitacoraCT;
    public get bitacoraCompraCT(): RepositorioBitacoraCT {
        return this._bitacoraCT;
    }

    private _ordenCompraSAP: RepositorioOrdenCompraSAP;
    public get ordenCompraSAP(): RepositorioOrdenCompraSAP {
        return this._ordenCompraSAP;
    }

    private _bitacoraEnvioOC: RepositorioBitacoraEnvioOCReferi;
    public get bitacoraEnvioOC(): RepositorioBitacoraEnvioOCReferi {
        return this._bitacoraEnvioOC;
    }


    private _tiposMotivosDocVenta: RepositorioTiposMotivosDocumentoVenta;
    public get motivosDocVenta(): RepositorioTiposMotivosDocumentoVenta {
        return this._tiposMotivosDocVenta;
    }

    private _envioOnMonitor: RepositorioEnvioOnMonitor;
    public get envioOnMonitor(): RepositorioEnvioOnMonitor{
        return this._envioOnMonitor;
    }

    private _proveeduria: RepositorioProveeduria;
    public get proveeduria(): RepositorioProveeduria {
        return this._proveeduria;
    }


    constructor(private cliente: HttpClient) {
        this._proveeduria = new RepositorioProveeduria(cliente);
        this._articulos = new RepositorioArticulo(cliente);
        this._cfdiEmisor= new RepositorioCfdiEmisor(cliente);
        this._usuarios = new RepositorioUsuario(cliente);
        this._camiones = new RepositorioCamion(cliente);
        this._permisos = new RepositorioPermiso(cliente);
        this._detallePermiso = new RepositorioDetallePermiso(cliente);
        this._roles = new RepositorioRol(cliente);
        this._socios = new RepositorioSocio(cliente);
        this._operadores = new RepositorioOperador(cliente);
        this._tiposDocumento = new RepositorioTipoDocumento(cliente);
        this._tipoUsuario = new RepositorioTipoUsuario(cliente);
        this._monedas = new RepositorioMoneda(cliente);
        this._textos = new RepositorioTextos(cliente);
        this._unidadLongitud = new RepositorioUnidadesLongitud(cliente);
        this._unidadPeso = new RepositorioUnidadesPeso(cliente);
        this._direccionesServicio = new RepositorioDireccionEntrega(cliente);
        this._tiposCarga = new RepositorioTipoCarga(cliente);
        this._trailer = new RepositorioTrailer(cliente);
        this._estadoProyecto = new RepositorioEstadosProyectos(cliente);
        this._proyectos = new RepositorioProyectos(cliente);
        this._direccionesSocio = new RepositorioDireccionSocio(cliente);
        this._contactosSocio = new RepositorioContactoSocio(cliente);
        this._correos = new RepositorioCorreo(cliente);
        this._impuestos = new RepositorioImpuesto(cliente);
        this._alertas = new RepositorioAlerta(cliente);
        this._detalleAlerta = new RepositorioDetalleAlerta(cliente);
        this._medidasLegales = new RepositorioMedidaLegal(cliente);
        this._compras = new RepositorioCompra(cliente);
        this._aduanas = new RepositorioAduana(cliente);
        this._fronteras = new RepositorioFrontera(cliente);
        this._tiposProyecto = new RepositorioTipoProyecto(cliente);
        this._unidades = new RepositorioUnidad(cliente);
        this._tiposUnidad = new RepositorioTipoUnidad(cliente);
        this._tiposCambio = new RepositorioTipoCambio(cliente);
        this._reportes = new RepositorioReportes(cliente);
        this._ventas = new RepositorioVenta(cliente);
        this._tipoTextos = new RepositorioTipoTextos(cliente);
        this._pais = new RepositorioPais(cliente);
        this._estado = new RepositorioEstado(cliente);
        this._ciudad = new RepositorioCiudad(cliente);
        this._tablas = new RepositorioTablas(cliente);
        this._rastreo = new RepositorioRastreo(cliente);
        this._facturasCompra = new RepositorioFacturaCompra(cliente);
        this._facturasVenta = new RepositorioFacturaVenta(cliente);
        this._proyectosExternos = new RepositorioProyectoExternos(cliente);
        this._documentosFactura = new RepositorioDocumentoFactura(cliente);
        this._empleados = new RepositorioEmpleado(cliente);
        this._motivosCancelacion = new RepositorioMotivoCancelacion(cliente);
        this._metasEmpleado = new RepositorioMetaEmpleado(cliente);
        this._documentosCompra = new RepositorioDocumentoCompra(cliente);
        this._documentosVenta = new RepositorioDocumentoVenta(cliente);
        this._estadoRastreo = new RepositorioEstadoRastreo(cliente);
        this._modoTransporte = new RepositorioModoTransporte(cliente);
        this._tipoProveedor = new RepositorioTipoProveedor(cliente);
        this._tipoServicio = new RepositorioTipoServicio(cliente);
        this._tipoCamion = new RepositorioTipoCamion(cliente);
        this._especificacionCarga = new RepositorioEspecificacionCarga(cliente);
        this._aplicaProveedorMina = new RepositorioAplicaProveedorMina(cliente);
        this._localidadesSocio = new RepositorioLocalidadesSocio(cliente);
        this._tipoReporte = new RepositorioTipoReporte(cliente);
        this._filtroReporte = new RepositorioFiltroReporte(cliente);
        this._reporteador = new RepositorioGeneradorReportes(cliente);
        this._calculo = new CalculoApi(cliente);
        this._tiposServicios = new RepositorioTiposServicios(cliente);
        this._servicios = new RepositorioServicios(cliente);
        this._evaluaciones = new RepositorioEvaluaciones(cliente);
        this._evaluacionesPE = new RepositorioEvaluacionesPE(cliente);
        this._exportacion = new RepositorioExportacionInfo(cliente)
        this._ServicioTransporteCliente= new RepositorioServicioTransporteCliente(cliente);
        this._reporteComisiones = new RepositorioReporteComisiones(cliente);
        this._envioPagoFactura = new RepositorioEnvioPagoFactura(cliente);
        this._gostoOperacion = new RepositorioGastoOperacion(cliente);
        this._ConceptoGasto = new RepositorioConceptoGasto(cliente);
        this._comprobanteCfdi = new RepositorioComprobante(cliente);
        this._tiposMotivos = new RepositorioTiposMotivos(cliente);
        this._tiposDocumentos = new RepositorioTiposDocumentos(cliente);
        this._bitacoraCT = new RepositorioBitacoraCT(cliente);
        this._solicitudesEmbarque = new RepositorioSolicitudesEmbarques(cliente);
        this._ordenCompraSAP = new RepositorioOrdenCompraSAP(cliente);
        this._bitacoraEnvioOC = new RepositorioBitacoraEnvioOCReferi(cliente);
        this._tiposMotivosDocVenta = new RepositorioTiposMotivosDocumentoVenta(cliente);
        this._motivosCierre = new RepositorioMotivoCierre(cliente);
        this._objetivos = new RepositorioObjetivos(cliente);
        this._tiposEstatusClientes = new RepositorioTipoEstatusCliente(cliente);
        this._tipoOpcion = new RepositorioTipoOpcion(cliente);
        this._estadosFacturacion = new RepositorioEstadoFacturacion(cliente);
        this._autorizaciones = new RepositorioAutorizaciones(cliente);
        this._antiguedadSaldos = new RepositorioAntiguedadSaldos(cliente);
        this._motivosDesfase = new RepositorioMotivoDesfase(cliente);
        this._estadosCotizacion = new RepositorioEstadoCotizacion(cliente);
        this._modosCotizacion = new RepositorioModoCotizacion(cliente);
        this._prioridadesCotizacion = new RepositorioPrioridadCotizacion(cliente);
    }
}
