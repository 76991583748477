import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  EventEmitter
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Texto } from 'src/app/entidades/texto';
import { ServicioAlerta } from 'src/app/utilerias/alerta.service';
import { Contexto } from 'src/app/api/contexto.service';
import { TipoTexto } from 'src/app/entidades/tipo-texto';
import { Socio } from 'src/app/entidades/socio';
import { Empleado } from 'src/app/entidades/empleado';
import { TextoEmpleados } from 'src/app/entidades/texto-empleados';

@Component({
  templateUrl: './modal-permisos.component.html',
  styleUrls: ['./modal-permisos.component.scss']
})
export class ModalTextosPermisosComponent implements OnInit, OnDestroy {
  forma: FormGroup;
  texto: TextoEmpleados[] = [];
  idSeleccionado = 0;
  cargando = false;
  subsGuardar: Subscription;
  guardado: EventEmitter<void> = new EventEmitter<void>();
  get f() {
    return this.forma.controls;
  }
  tiposTextosPredeterminados: TipoTexto[];
  empleados: Empleado[] = [];
  empleadosSeleccionados: any[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public id: number,
    private ventana: MatDialogRef<ModalTextosPermisosComponent>,
    private formBuilder: FormBuilder,
    private ctx: Contexto,
    private alerta: ServicioAlerta
  ) {}

  ngOnInit() {
    this.forma = this.formBuilder.group({
      empleadosId: [this.empleadosSeleccionados || []],
      textoId: [this.id]
    });
    this.cargarTiposPredeterminados();
    this.cargarEmpleadosPorId();
  }

  limpiar(): void {
    this.forma.reset();
  }

  cargarTiposPredeterminados(): void {
    this.ctx.empleados.obtenerTodos().subscribe((empleados) => {
      this.empleados = empleados;
    })
  }
  cargarEmpleadosPorId(): void{
    this.ctx.textos.obtenerEmpleadosPorId(this.id).subscribe((empleados) => {
      this.empleadosSeleccionados = empleados.map(e => e.empleadoId);
      this.forma.get('empleadosId').setValue(this.empleadosSeleccionados);
    })
  }

  guardar(): void {
    if (this.forma.valid) {
      this.cargando = true;

      const formData = this.forma.value;
      const empleadosIds = formData.empleadosId;
      const payload = empleadosIds.map((empleadoId: number) => ({
        empleadoId: empleadoId,
        textoPredeterminadoId: formData.textoId,
      }));
      Object.assign(this.texto, payload);
      this.ctx.textos.CambioPermisos(this.texto).subscribe((permisos) => {
        if(permisos != null){
          this.cargando = false;
          this.alerta.mostrarExito('Permisos guardados con exito');
          this.cerrar();
        }else{
          this.cargando = false;
          this.alerta.mostrarError('Error al guardar permisos');
        }
      })
    }
  }

  cerrar(): void {
    this.ventana.close();
  }

  ngOnDestroy(): void {
    if (this.subsGuardar) {
      this.subsGuardar.unsubscribe();
    }
  }
}
